import React from 'react'
import {graphql} from 'gatsby'
import {Link} from 'gatsby'
import Img from "gatsby-image"
import {format, distanceInWords, differenceInDays} from 'date-fns'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../lib/helpers'
import BlogPostPreviewList from '../components/blog-post-preview-list'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import styled from 'styled-components'
import { ContainerFullWidth, ContainerMain } from '../containers'
import Hero from '../components/hero'
import PortableText from '../components/portableText'
import { FcCalculator, FcBullish, FcMoneyTransfer, FcComboChart, FcPositiveDynamic,
  FcBearish, FcHighPriority, FcCalendar, FcHome, FcCurrencyExchange, FcFlashOn, FcAutomotive, FcIcons8Cup, 
  FcPieChart, FcMindMap, FcList, FcClock, FcSportsMode, FcOnlineSupport } from "react-icons/fc";
import {PostPreview, PostsGrid} from './index'

import Eth from "../assets/svgs/eth.svg";
import Ada from "../assets/svgs/ada.svg";
import Algo from "../assets/svgs/algo.svg";
import Atom from "../assets/svgs/atom.svg";
import Dot from "../assets/svgs/dot.svg";
import Ksm from "../assets/svgs/ksm.svg";
import Sol from "../assets/svgs/sol.svg";
import Usdc from "../assets/svgs/usdc.svg";
import Xtz from "../assets/svgs/xtz.svg";
import Flow from "../assets/svgs/flow.svg";
import Kava from "../assets/svgs/kava.svg";
import Luna from "../assets/svgs/luna.svg";
import Anchor from "../assets/svgs/anchor.svg";
import Avax from "../assets/svgs/avax.svg";

import { GumroadAd } from '../components/GumroadAd'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query ToolsPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    posts: allSanityPost(
      limit: 20
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          authors{
            author{
              name
              id
            }
          }
          mainImage {
            asset{
              fluid {
                ...GatsbySanityImageFluid
              }
              fixed(width: 400) {
                ...GatsbySanityImageFixed
              }
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const Title = styled.h1`
  text-align: center;
`

const CategoryTitle = styled.h2`
  font-size: 1rem;
  text-transform: uppercase;
  color: ${props => props.theme.theme.text.tertiary};
`

const ToolItemContainer = styled.div`
  padding: 12px;
  box-sizing: border-box;
  display: grid;
  align-items: center;
  /* align-content: flex-start; */
  grid-template-columns: 75px 1fr;
  grid-template-rows: 100px;
  background-color: ${props => props.theme.theme.bg.primary};
  color: ${props => props.theme.theme.text.primary};
  /* border: 1px solid ${ props => props.theme.theme.border.secondary}; */
  border: 3px solid ${props => props.theme.theme.bg.primary};
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  height: 100%;
  grid-gap: 24px;
  border-radius: 8px;
  overflow: hidden;
  transition: box-shadow 0.2s ease-in-out;

  svg {
    width: 100%;
    height: 100%;
  }

  h2{
    font-size: 1.4rem;
    margin: 0;
  }

  p{
    margin: 0;
    color: ${props => props.theme.theme.text.tertiary};
    font-size: 1rem;
  }

  &:hover {
    border: 3px solid ${props => props.theme.theme.colors.green};
    transition: border-color 0.2s ease-in-out;
      /* border: 1px solid ${ props => props.theme.theme.border.tertiary}; */
    /* background-color: ${props => props.theme.theme.bg.tertiary}; */
    /* box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.1); */
    /* transition: box-shadow 0.2s ease-in-out; */

      /* h2 {
        text-decoration: underline;
      } */
    }

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    grid-gap: 12px;
    padding: 24px;
  }
`

const ToolsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); */
  grid-gap: 24px;
  box-sizing: border-box;
  margin: 0 0 36px 0;

  p {
    font-family: 'Inter', sans-serif;
  }

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`

const ToolItem = ( {toolItem} ) => {
  return(
    <Link to={`/tools/${toolItem.url}`}>
      <ToolItemContainer>
        {toolItem.icon}
        <div>
          <h2>{toolItem.name}</h2>
          <p>{toolItem.desc}</p>
        </div>
      </ToolItemContainer>
    </Link>
  )
}

const fiTools = [
  {
    name: 'Coast FIRE Calculator',
    url: 'coast-fire-calc',
    icon: <FcCalculator />,
    desc: 'How much do you need to save to Coast to FIRE?'
  },
  {
    name: 'FIRE Calculator',
    url: 'fire-calculator',
    icon: <FcBullish />,
    desc: 'Plan your journey to Financial Independence / Retire Early'
  },
  {
    name: 'Fat FIRE Calculator',
    url: 'fat-fire-calculator',
    icon: <FcComboChart />,
    desc: 'Plan your journey to FIRE with a fat nest egg'
  },
  {
    name: 'Barista FIRE Calculator',
    url: 'barista-fire-calc',
    icon: <FcIcons8Cup />,
    desc: 'Unlock financial freedom earlier with side income after quitting your day job'
  },
  {
    name: 'Coast FIRE Jobs Database',
    url: 'coast-fire-jobs-database',
    icon: <FcList />,
    desc: 'A crowdsourced database of potential Coast FIRE jobs'
  },
  {
    name: 'Purchase Impact on FIRE Timeline Calculator',
    url: 'fire-purchase-impact-calculator',
    icon: <FcClock />,
    desc: 'Calculate how much a one-time purchase impacts your FIRE timeline'
  },
  {
    name: 'Windfall Impact on FIRE Timeline Calculator',
    url: 'fire-windfall-impact-calculator',
    icon: <FcClock />,
    desc: 'Calculate how much a windfall impacts your FIRE timeline'
  }
]

const cryptoTools = [
  {
    name: 'USD Coin Interest Calculator',
    url: 'usdc-calculator',
    icon: <Usdc />,
    desc: 'Calculate how much interest you can earn on your USDC deposits'
  },
  {
    name: 'Ethereum Staking Rewards Calculator',
    url: 'ethereum-staking-calculator',
    icon: <Eth />,
    desc: 'How much can you earn by staking your Ethereum (ETH)?'
  },
  {
    name: 'Kraken Polkadot Staking Calculator',
    url: 'kraken-polkadot',
    icon: <Dot />,
    desc: 'How much can you earn by staking your Polkadot (DOT) with Kraken?'
  },
  {
    name: 'Coinbase USD Coin (USDC) Interest Calculator',
    url: 'coinbase-usdc-calculator',
    icon: <Usdc />,
    desc: 'Calculate how much interest you can earn on your USDC deposits at Coinbase'
  },
  {
    name: 'Algorand (ALGO) Staking Rewards Calculator',
    url: 'algorand-staking-calculator',
    icon: <Algo />,
    desc: 'How much can you earn by staking your Algorand (ALGO)?'
  },
  {
    name: 'Cardano (ADA) Staking Rewards Calculator',
    url: 'cardano-staking-calculator',
    icon: <Ada />,
    desc: 'How much can you earn by staking your Cardano (ADA)?'
  },
  {
    name: 'Cosmos (ATOM) Staking Rewards Calculator',
    url: 'cosmos-staking-calculator',
    icon: <Atom />,
    desc: 'How much can you earn by staking your Cosmos (ATOM)?'
  },
  {
    name: 'Solana (SOL) Staking Rewards Calculator',
    url: 'solana-staking-calculator',
    icon: <Sol />,
    desc: 'How much can you earn by staking your Solana (SOL)?'
  },
  {
    name: 'Tezos (XTZ) Staking Rewards Calculator',
    url: 'tezos-staking-calculator',
    icon: <Xtz />,
    desc: 'How much can you earn by staking your Tezos (XTZ)?'
  },
  {
    name: 'Flow (FLOW) Staking Rewards Calculator',
    url: 'flow-staking-calculator',
    icon: <Flow />,
    desc: 'How much can you earn by staking your Flow (FLOW)?'
  },
  {
    name: 'Kusama (KSM) Staking Rewards Calculator',
    url: 'kusama-staking-calculator',
    icon: <Ksm />,
    desc: 'How much can you earn by staking your Kusama (KSM)?'
  },
  {
    name: 'Kava (KAVA) Staking Rewards Calculator',
    url: 'kava-staking-calculator',
    icon: <Kava />,
    desc: 'How much can you earn by staking your Kava (KAVA)?'
  },
  {
    name: 'Avalanche (AVAX) Staking Rewards Calculator',
    url: 'avalanche-staking-calculator',
    icon: <Avax />,
    desc: 'How much can you earn by staking your Avalanche (AVAX)?'
  }
]

const savingTools = [
  {
    name: 'Savings Rate Calculator',
    url: 'savings-rate-calculator',
    icon: <FcMoneyTransfer />,
    desc: 'What is your Savings Rate?'
  },
  {
    name: 'Emergency Fund Calculator',
    url: 'emergency-fund-calculator',
    icon: <FcHighPriority />,
    desc: 'How much cash should you have saved up in case of an emergency?'
  },
  {
    name: 'Electric Car Savings Calculator',
    url: 'electric-car-savings-calc',
    icon: <FcFlashOn />,
    desc: 'Discover how much you can save by switching to an electric vehicle'
  },
  {
    name: 'Gas Mileage Savings Calculator',
    url: 'gas-mileage-savings-calc',
    icon: <FcAutomotive />,
    desc: 'Compare annual fuel costs between two vehicles and calculate the break-even point'
  },
  {
    name: 'Hybrid vs Gas Savings Calculator',
    url: 'hybrid-vs-gas-savings-calculator',
    icon: <FcAutomotive />,
    desc: 'Compare annual fuel costs between a gas vehicle and a hybrid and calculate the break-even point'
  },
  {
    name: 'Keeping up with Inflation Calculator',
    url: 'keeping-up-with-inflation-calculator',
    icon: <FcSportsMode />,
    desc: 'Calculate how much of a salary raise you need to keep up with inflation'
  },
  {
    name: 'Upwork Fee Calculator and Guide',
    url: 'upwork-fee-calculator',
    icon: <FcOnlineSupport />,
    desc: 'Calculate your take home earnings as a freelancer on Upwork'
  }
]

const investingLoansTools = [
  {
    name: 'House Hacking Calculator',
    url: 'house-hacking-calculator',
    icon: <FcHome />,
    desc: 'Accelerate your path to financial freedom by being a live-in landlord'
  },
  {
    name: 'Compound Interest Calculator',
    url: 'compound-interest-calculator',
    icon: <FcPositiveDynamic />,
    desc: 'Visualize the incredible power of compound growth'
  },
  {
    name: 'Portfolio Rebalancing Calculator',
    url: 'portfolio-rebalancing-calc',
    icon: <FcPieChart />,
    desc: 'Calculate how to rebalance your current portfolio to achieve your target asset allocations'
  },
  {
    name: '15 Year vs 30 Year Morgage Calculator',
    url: '15-vs-30-year-mortgage-calculator',
    icon: <FcHome />,
    desc: 'Compare your monthly payments and total cost between a 15 year and a 30 year mortgage'
  },
  {
    name: 'Loan Payment Calculator',
    url: 'loan-calculator',
    icon: <FcCalendar />,
    desc: 'Calculate your loan payments over time and visualize your amortization schedule'
  },
  {
    name: 'Rental Property Calculator for Real Estate Investing',
    url: 'rental-property-calculator',
    icon: <FcHome />,
    desc: 'Analyze key financial metrics for your next real estate deal'
  }
]

const ToolsPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const posts = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO
        title={'Tools'}
        description={site.description}
        keywords={site.keywords}
      />

      <ContainerMain>
        <Title>Tools</Title>
        <CategoryTitle>Financial Independence / Retire Early</CategoryTitle>
        <ToolsGrid>
          {fiTools.map(tool => (<ToolItem toolItem={tool} />)) }
        </ToolsGrid>

      <GumroadAd />

        <CategoryTitle>Investing and Loans</CategoryTitle>
        <ToolsGrid>
          {investingLoansTools.map(tool => (<ToolItem toolItem={tool} />)) }
        </ToolsGrid>

        <CategoryTitle>Saving</CategoryTitle>
        <ToolsGrid>
          {savingTools.map(tool => (<ToolItem toolItem={tool} />)) }
        </ToolsGrid>

        <CategoryTitle>Crypto</CategoryTitle>
        <ToolsGrid>
          {cryptoTools.map(tool => (<ToolItem toolItem={tool} />)) }
        </ToolsGrid>
      </ContainerMain>

      {/* <Container>
        <h1 hidden>Welcome to {site.title}</h1>
        {postNodes && (
          <BlogPostPreviewList
            title='Latest blog posts'
            nodes={postNodes}
            browseMoreHref='/archive/'
          />
        )}
      </Container> */}
    </Layout>
  )
}

export default ToolsPage
